

export const airlineLogo = {
    AAL: require("../assets/american_airline_1.png"),
    ASA: require("../assets/alaska_airline.png"),
    DAL: require("../assets/delta_airline.png"),
    FFT: require("../assets/frontier.png"),
    EDV: require("../assets/cessna_airline.png"),
    JBU: require("../assets/jetBlue.png"),
    UAL: require("../assets/united_airline.png"),
    SWA: require("../assets/southwest_airline.png"),
    NKS: require("../assets/spirit.png"),
    ICE: require("../assets/iceland.png"),
    DLH: require("../assets/lufthansa.png"),
    CMP: require("../assets/copa_airline.png"),
    VXP: require("../assets/avelo.jpg"),
    BHS: require("../assets/bahama_air.png"),
    BRE: require("../assets/breeze.png"),
    SCX: require("../assets/sun_country.png"),
    AFR: require("../assets/air_france.png"),
    ROU: require("../assets/aircanada.gif"),
  };
  
  export const parseTimeStringTo24HourFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    let [hours, min] = time.split(":");
    if (hours === "12") hours = "00";
    if (period === "PM") hours = parseInt(hours, 10) + 12;
  
    const currentDate = new Date();
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      min
    );
  };
  
  export const abbreviateDuration = (durationString) => {
    if (!durationString) return '';
    const durationParts = durationString.split(" ");
    let hours = "";
    let minutes = "";
  
    durationParts.forEach((part, i) => {
      if (part.includes("hour")) hours = `${durationParts[i - 1]}h`;
      if (part.includes("minute")) minutes = `${durationParts[i - 1]}m`;
    });
  
    return `${hours} ${minutes}`.trim();
  };
  
  export const processFlightData = (flightData, airlineLogo) => {
    return flightData
      .map((flight) => {
        const airlineCode = flight.flight_number.slice(0, 3);
        const logo = airlineLogo[airlineCode] || null;
        return {
          ...flight,
          airline_logo: logo,
          scheduledTime: parseTimeStringTo24HourFormat(flight.time),
        };
      })
      .sort((a, b) => a.scheduledTime - b.scheduledTime);
  };
