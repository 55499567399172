import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import { Auth0Provider } from "@auth0/auth0-react";

const domain = "dev-jjyf3yzbfxkq5s1m.us.auth0.com";
const clientId = "iQGJNKkbeTKYgS9Mc21YLE9YMzByUgMP";
const audience = "wss://flyby.colonmelvin.com:8000"; // Optional: Add if you're using an API with Auth0

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        scope: "openid"
      }}
    >
      <App />
    </Auth0Provider>
  // </React.StrictMode>
);