import React from "react";
import { Plane, Send, AlertCircle } from "lucide-react";
import { abbreviateDuration } from "./utils/flightUtils";
import "./FlightDisplay.css";

const FlightDisplay = ({ currentFlight }) => {
  const isArrival = currentFlight.type === "arrival";

  return (
    <div className="flight-display">
      <table className="flight-table">
        <thead>
          <tr>
            <th>Flight</th>
            <th>{isArrival ? "Arriving From" : "Traveling To"}</th>
            <th>{isArrival ? "Landed" : "Departure Time"}</th>
            <th>{isArrival ? "Runway" : "Gate"}</th>
            <th>{isArrival ? "Duration" : "Aircraft"}</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="logo-container">
              {isArrival ? (
                <Plane className="icon-plane" size={20} />
              ) : (
                <Send className="icon-plane" size={20} />
              )}
              <span className="flight-number">{currentFlight.flight_number}</span>
              {currentFlight.airline_logo && (
                <img
                  src={currentFlight.airline_logo}
                  alt={`${currentFlight.flight_number} logo`}
                  className="airline-logo-display"
                />
              )}
            </td>
            <td>

              {isArrival
                ? currentFlight.origin_city + (currentFlight.origin ? ` (${currentFlight.origin})` : '')
                : currentFlight.destination_city
                  ? `${currentFlight.destination_city}${currentFlight.destination ? ` (${currentFlight.destination})` : ''}`
                  : ''}
            </td>
            <td className="custom-runway-data">{currentFlight.time}</td>
            <td className="custom-runway-data">
              {currentFlight.runway || currentFlight.gate}
            </td>
            <td>
              {currentFlight.flight_time
                ? abbreviateDuration(currentFlight.flight_time)
                : currentFlight.aircraft_type}
            </td>
            <td className={`flight-status ${currentFlight.status?.toLowerCase().includes("delayed") ? "delayed-status" : ""}`}>
              {currentFlight.status}
              {currentFlight.status?.toLowerCase().includes("delayed") && (
                <AlertCircle className="icon-warning" size={16} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FlightDisplay;
