import React, { useState, useEffect, useCallback } from "react";
import MOCK_FLIGHT_DATA from "../../flightData.json";
import logo from "../../assets/logo-final.png";
import WeatherData from "../../components/WeatherData";
import FlightDisplay from "../../components/FlightBanner/FlightDisplay";
import { parseTimeStringTo24HourFormat } from "./utils/flightUtils.js";
import "./FlightBanner.css";

import { airlineLogo, API_ADDRESS, DEVELOPMENT_API_ADDRESS, DEFAULT_JSON_DATA } from "./constants/flightConstants.js";
import { useAuth0 } from "@auth0/auth0-react";

const DATA_SOURCE = 'prod'; // Options: 'json', 'dev', 'prod'

const getApiUrl = (airport) => {
  switch (DATA_SOURCE) {
    case 'json':
      return DEFAULT_JSON_DATA;
    case 'dev':
      return `${DEVELOPMENT_API_ADDRESS}/${airport}`;
    case 'prod':
    default:
      return `${API_ADDRESS}/${airport}`;
  }
};
const FlightBanner = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [flights, setFlights] = useState([]);
  const [currentFlightIndex, setCurrentFlightIndex] = useState(0);
  const [weatherCity, setCity] = useState('');

  const fetchFlightData = useCallback(async () => {
    try {
      const airport = getAirportFromUrl();
      let data;
      if (DATA_SOURCE === 'json') {
        data = { flights: MOCK_FLIGHT_DATA };
      } else {
        const token = await getAccessTokenSilently();
        const response = await fetch(getApiUrl(airport), {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch flight data');
        }
        data = await response.json();
      }
      setCity(data.weather.city);

      const processedFlights = data.flights
        .map((flight) => ({
          ...flight,
          airline_logo: airlineLogo[flight.flight_number.slice(0, 3)] || null,
          scheduledTime: parseTimeStringTo24HourFormat(flight.time),
        }))
        .sort((a, b) => a.scheduledTime - b.scheduledTime);
      setFlights(processedFlights);
    } catch (error) {
      console.error("Error fetching flight data:", error);
    }
  }, [getAccessTokenSilently]);
  useEffect(() => {
    fetchFlightData();
    // Set up an interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchFlightData, 300000);
    return () => clearInterval(intervalId);
  }, [fetchFlightData]);

  useEffect(() => {
    const flightSwitchInterval = setInterval(() => {
      setCurrentFlightIndex((prevIndex) => (prevIndex + 1) % flights.length);
    }, 5000);

    return () => clearInterval(flightSwitchInterval);
  }, [flights]);

  if (flights.length === 0) return null;

  const currentFlight = flights[currentFlightIndex];

  return (
    <div className="main flight-banner-container">
      <div className="flight-banner-wrapper">
        <div className="flight-banner-content">

          <div className="logo-transition flight-banner-live logo">
            <img src={logo} alt="FlyBySpotter" className="flyby-text-banner" />
            <div className="live-banner-text">LIVE</div>
          </div>
        </div>
        <FlightDisplay currentFlight={currentFlight} />
        <WeatherData city={weatherCity} />
      </div>
    </div>
  );
};
const getAirportFromUrl = () => {
  const path = window.location.pathname;
  const airport = path.split('/').pop().toUpperCase();
  return airport || 'RDU'; // Default to RDU if not specified
};

export default FlightBanner;


