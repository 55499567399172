import React, { useEffect, useState } from "react";
import axios from "axios";
import "./WeatherData.css";

function WeatherData({ city }) {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString("en-US", {
      month: "short", 
      day: "numeric",
    })
  );
  const [unit, setUnit] = useState("F");

  useEffect(() => {
    const fetchWeather = async () => {
      const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;
      const URL = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=imperial&appid=${API_KEY}`;
  
      try {
        const response = await axios.get(URL);
        const data = response.data;
  
        setWeatherData({
          city: data.name,
          temperature: Math.round(data.main.temp),
          feels_like: data.main.feels_like,
          description: data.weather[0].description,
          icon: `http://openweathermap.org/img/w/${data.weather[0].icon}.png`,
        });
  
        setLoading(false);
      } catch (err) {
        setError("Error fetching weather data");
        setLoading(false);
      }
    };
  
    fetchWeather();
  
    const timeInterval = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
      setCurrentDate(
        new Date().toLocaleDateString("en-US", {
          month: "short",
          day: "numeric", 
        })
      );
    }, 60000);
  
    const unitSwitchInterval = setInterval(() => {
      setUnit((prevUnit) => (prevUnit === "F" ? "C" : "F")); // Toggle between 'F' and 'C'
    }, 5000);
  
    return () => {
      clearInterval(timeInterval);
      clearInterval(unitSwitchInterval);
    };
  }, [city]);

  const convertTemperature = (temp) => {
    if (unit === "C") {
      return Math.round((temp - 32) * (5 / 9)); // Convert Fahrenheit to Celsius
    }
    return temp;
  };

  const [time, period] = currentTime.split(" ");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="weather-widget">
        <div className="weather-content">
          <div className="weather-section weather-info">
            <img
              className="widget-icon"
              src={weatherData?.icon}
              alt="Weather Icon"
            />
            <h2>{weatherData?.city}</h2>
            <h3>
              {convertTemperature(weatherData?.temperature)}°{unit}
            </h3>
          </div>
          <div className="weather-section date-time">
            <p>{currentDate}</p>
            <h1>
              {time}
              <span className="time-period">{period}</span>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeatherData;
